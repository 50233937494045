import * as React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/shared/layout";

import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  useAnimate,
  useMotionValue,
} from "framer-motion";
import { LogoBottom, LogoTop } from "../images/logos";

const TestPage = (props) => {
  // ---> Get all Signs <--- //
  const projects = props.data.allStrapiProject.nodes;
  const works = props.data.allStrapiWork.nodes;

  const logoRef = React.useRef(null);
  const { scrollY } = useScroll({
    ref: logoRef,
    offset: ["end end", "start start"],
  });

  const scrollYTransform = useTransform(scrollY, [0, 400], [0, -80]);

  const y1 = useSpring(scrollYTransform, {
    stiffness: 1000,
    damping: 100,
    restDelta: 0.1,
  });

  const [scrollDown, setScrollDown] = React.useState(false);

  const [scope, animate] = useAnimate();
  const pathLength = useMotionValue(0);

  React.useEffect(() => {
    const controls = animate(pathLength, 1, {
      duration: 5,
      onComplete: () => {
        setScrollDown(true);
      },
    });
    return controls.stop;
  }, []);

  return (
    <Layout
      location={props.location}
      title={props.data.site.siteMetadata.title}
    >
      {/* LOGO */}
      <div ref={scope}>
        <div className="text-black">{pathLength.cy}</div>
        {/* <motion.div style={{ pathLength, opacity: 1 }}>asiuyiuyiuyd</motion.div> */}
        <svg viewBox="0 0 200 200" className="h-[1rem] w-[1rem]">
          <motion.circle
            cx="100"
            cy="100"
            r="50"
            fill="none"
            strokeWidth="100"
            pathLength="0"
            stroke="red"
            style={{ pathLength, opacity: 1 }}
          />
        </svg>
      </div>
    </Layout>
  );
};
export const query = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allStrapiProject {
      nodes {
        title
        year
        projectType {
          projectType
        }
        heroImage {
          imageFile {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: NO_CHANGE
                  width: 700
                  #height: 100
                  quality: 100
                  #layout: FULL_WIDTH
                  transformOptions: { fit: COVER }
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
        contentType {
          ... on STRAPI__COMPONENT_EXHIBITION_OTHER {
            strapi_component
            textWYSIWYG {
              data {
                textWYSIWYG
              }
            }
          }
          ... on STRAPI__COMPONENT_EXHIBITION_EXHIBITION {
            strapi_component
            curatorName {
              firstName
              lastName
            }
            text {
              data {
                text
              }
            }
          }
        }
      }
    }
    allStrapiWork(limit: 6, sort: { order: DESC, fields: publishedAt }) {
      nodes {
        collection {
          collectionName
        }
        mediums {
          mediumType
        }
        title
        year
        artist {
          firstName
          lastName
          slug
        }
        heroImage {
          imageFile {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: NO_CHANGE
                  width: 700
                  #height: 100
                  quality: 100
                  #layout: FULL_WIDTH
                  transformOptions: { fit: COVER }
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
  }
`;

export default TestPage;
